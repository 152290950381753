import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';


function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/documents' element={<h1>Documents</h1>} />
    </Routes>
  );
}

export default App;
